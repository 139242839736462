<template>
  <div class="">
    <SiteNav />

    <section class="bg-mint h-screen hero pt-12 pb-20 lg:pt-32 lg:pb-0">
      <!-- <div class="absolute left-0 top-0 w-full h-full" style="z-index: -1"></div> -->

      <div class="l-container">
        <div class="text-center flex flex-col md:flex-col-reverse lg:text-left lg:grid grid-cols-2 gap-8 items-start">
          <div class="md:mt-5">
            <p style="color: #191919" class="mb-9">ABOUT US</p>
            <h1 style="color: #191919; font-size: 52px" class="hidden md:block leading-none font-bold mb-4">
              We’re a team <br />
              of young <br />
              and enterprising <br />
              leaders
            </h1>
            <h1 style="color: #191919; font-size: 32px" class="md:hidden leading-none font-bold mb-4">
              We’re a team of young <br />
              and enterprising leaders
            </h1>
            <p class="w-4/5 mx-auto lg:mx-0 mb-6">
              Through collaboration, innovation, and continuous learning, we ensure that we stay ahead of the curve and
              more importantly, on top of our customers’ needs in this ever-evolving digital landscape.
            </p>
          </div>
          <div class="max-w-lg mx-auto">
            <img src="./assets/about-hero.webp" />
          </div>
        </div>
      </div>
    </section>

    <section style="z-index: 999" id="founders" class="bg-white pt-12 round -mt-32 md:-mt-20">
      <div class="l-container">
        <div class="flex md:flex-row flex-col gap-3 items-center md:justify-between">
          <h2 style="color: #474747; font-size: 38px" class="hidden md:block w-full leading-none font-bold">
            Our <br />
            Co-founders
          </h2>

          <h2 style="color: #474747; font-size: 24px" class="md:hidden text-center w-full leading-none font-bold">
            Our Co-founders
          </h2>

          <p class="text-sm md:text-xs text-center md:text-right w-full leading-snug">
            From visionary leaders to meticulous developers, creative <br />
            designers and dedicated sales marketers, our eclectic team <br />
            combines expertise, passion and creativity to deliver excellent <br />
            and solution-oriented products and services to our customers.
          </p>
        </div>

        <div class="flex md:flex-row flex-col my-16 gap-5">
          <div
            @mouseover="hoverName = 'asher'"
            @mouseleave="hoverName = ''"
            @mouseenter="hoverName = 'asher'"
            style="border-radius: 24px; height: 500px"
            :class="
              hoverName.length == 0
                ? 'md:w-11/12 bg-cover'
                : hoverName === 'asher'
                ? 'col-span-1 w-full bg-cover'
                : 'md:w-8/12 bg-cover col-span-1'
            "
            class="first relative image-container md:grow overflow-hidden"
          >
            <div class="z-50 flex flex-col gap-1 absolute bottom-10 left-5">
              <h4 class="text-white font-bold text">Asher Adeniyi</h4>
              <h6 class="text-white text-xs text">Chief Executive Officer</h6>
            </div>
            <div class="overlay"></div>
          </div>

          <div
            @mouseover="hoverName = 'efosa'"
            @mouseleave="hoverName = ''"
            @mouseenter="hoverName = 'efosa'"
            style="border-radius: 24px; height: 500px"
            :class="
              hoverName.length == 0
                ? 'md:w-11/12 bg-cover'
                : hoverName === 'efosa'
                ? 'col-span-1 w-full bg-cover'
                : 'md:w-8/12 bg-cover col-span-1'
            "
            class="second relative image-container md:grow overflow-hidden"
          >
            <!-- <img src="./assets/efosa.png" alt="efosa-uwoghiren" class="w-full" /> -->
            <div class="z-50 flex flex-col gap-1 absolute bottom-10 left-5">
              <h4 class="text-white font-bold text">Emmanuel Efosa-zuwa</h4>
              <h6 class="text-white text-xs text capitalize">Chief Product & growth officer</h6>
            </div>
            <div class="overlay"></div>
          </div>

          <div
            @mouseleave="hoverName = ''"
            @mouseenter="hoverName = 'kingsley'"
            style="border-radius: 24px; height: 500px"
            :class="
              hoverName.length == 0
                ? 'md:w-11/12 bg-cover'
                : hoverName === 'kingsley'
                ? 'col-span-1 w-full bg-cover'
                : 'md:w-8/12 bg-cover col-span-1'
            "
            class="third relative image-container md:grow overflow-hidden"
          >
            <!-- <img src="./assets/kingsley.png" alt="kingsley-michael" class="w-full" /> -->
            <div class="z-50 flex flex-col gap-1 absolute bottom-10 left-5">
              <h4 class="text-white font-bold text">Kingsley Michael</h4>
              <h6 class="text-white text-xs capitalize text">Chief Technology Officer</h6>
            </div>
            <div class="overlay"></div>
          </div>
        </div>
      </div>
    </section>

    <section style="z-index: 999" id="staff" class="bg-landingGrey pb-8 pt-1">
      <div class="l-container">
        <div class="flex items-center mt-12 md:mt-24 justify-between">
          <h3 style="color: #474747" class="text-3xl font-medium">
            We are not just a team, <br />
            we are a <span class="text-secondary font-bold">tribe!</span>
          </h3>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-5 my-16 gap-x-2 gap-y-7 justify-center items-center">
          <div
            v-for="staff in staffData"
            :key="staff.name"
            class="w-full justify-center flex gap-3 flex-col overflow-hidden"
          >
            <img :src="staff.img" :alt="staff.name" class="w-11/12" />
            <span style="color: #191919" class="text-sm text-center">{{ staff.name }}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { watch, ref, onMounted } from "vue";
import fayo from "./assets/fayo.png";
import grace from "./assets/grace.png";
import ethel from "./assets/ethel.png";
import toyin from "./assets/toyin.png";
import ayo from "./assets/ayo.png";
import esosa from "./assets/esosa.png";
import joy from "./assets/joy.png";
import akan from "./assets/akan.png";
import abdul from "./assets/abdul.png";
import ifedayo from "./assets/ifedayo.png";
import ime from "./assets/ime.png";
import anorue from "./assets/anorue.png";
import peter from "./assets/peter.png";
import ayooluwa from "./assets/ayooluwa.png";
import elizabeth from "./assets/elizabeth.png";
import constance from "./assets/constance.png";
import peace from "./assets/peace.png";
import immonite from "./assets/immonite.png";
import banke from "./assets/banke.png";

export default {
  data() {
    return {
      hoverName: "",
      staffData: [
        { name: "Fayokemi Adepitan", img: fayo },
        { name: "Grace Jude", img: grace },
        { name: "Ethel Omame", img: ethel },
        { name: "Oluwatoyin Faleke", img: toyin },
        { name: "Ayomide Asekun", img: ayo },
        { name: "Esosa Uwoghiren", img: esosa },
        { name: "Joy Arawore", img: joy },
        { name: "Akanowo Uko", img: akan },
        { name: "Abdulrahman Salau", img: abdul },
        { name: "Ifedayo Adesiyan", img: ifedayo },
        { name: "Ime Ekwere", img: ime },
        { name: "Michael Anorue", img: anorue },
        { name: "Peter Whyte", img: peter },
        { name: "Ayooluwa Falaye", img: ayooluwa },
        { name: "Elizabeth Olasupo", img: elizabeth },
        { name: "Constance Erhagbai", img: constance },
        { name: "Peace Solomon", img: peace },
        { name: "Michael Imonitie", img: immonite },
        { name: "Olabanke Subair", img: banke },
      ],
    };
  },
  methods: {
    async scrollToSection() {
      // Get a reference to the section element
      const section = this.$refs.calculator;

      // Ensure the section element exists
      if (section) {
        // Scroll to the section using scrollIntoView

        await this.$nextTick(() => section.scrollIntoView({ behavior: "smooth", block: "center" }));
        // this.$nextTick(() => section.scrollIntoView({ behavior: "smooth" }));
      }
    },
  },
  async mounted() {
    this.scrollItem = true;
    // Call scrollToSection when the component is mounted
    // this.scrollToSection();
    await this.$nextTick();

    if (this.$route.query.scroll) {
      this.scrollToSection();
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("../../styles/www.scss");

.first {
  background-image: url(./assets/asher.png);

  background-repeat: no-repeat;
}

.second {
  background-image: url(./assets/efosa.png);

  background-repeat: no-repeat;
}

.third {
  background-image: url(./assets/kingsley.png);

  background-repeat: no-repeat;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.6) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.text {
  opacity: 0;
}

.image-container {
  transition: all 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 1;
}
.image-container:hover .text {
  opacity: 1;
}
.round {
  border-top-left-radius: 80px !important;
}
.hero {
  .heading {
    color: #11453b;
  }
}

.features-section {
  .feature-list {
    display: grid;
    grid-gap: 80px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 28px;

      .media {
        max-width: 300px;
        flex-shrink: 0;
      }

      .content {
        max-width: 500px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      .title {
        font-size: 30px;
        margin-bottom: 16px;
      }
    }
  }

  li.compliance {
    margin-top: -25px; /* to reconcile the spacing at the top of the image */
  }
}

.payroll-calculator-section {
  background-color: #f0f7eb;
}

@screen lg {
  .features-section {
    .feature-list {
      grid-gap: 100px;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0;

        .media {
          width: 45%;
          max-width: unset;
          flex-shrink: 0;
        }

        .content {
          width: 50%;
          max-width: unset;
          margin-left: 0;
          margin-right: 0;
          text-align: left;
        }
        .title {
          font-size: 50px;
          margin-bottom: 16px;
        }
      }

      li.reverse {
        flex-direction: row-reverse;
      }
      li.compliance {
        margin-top: 0px; /* to */
      }
    }
  }
}
</style>
